const lastscreenReducer = (state = { last_lesson: 0, last_sublesson: 0}, action) => {
    switch(action.type) {
      case "SET_LASTSCREEN":
         return action.payload.lastscreen;
      default:
         return state;
    }
}

export default lastscreenReducer;

import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.scss";
import "./App.css";

const App = () => {

  const userType = useSelector(state => state.auth.userType);

  const Disclaimer = React.lazy(() => import('./components/disclaimer/disclaimer'));
  const Privacy = React.lazy(() => import('./components/disclaimer/privacy'));
  const Registration = React.lazy(() => import('./components/registration'));
  const Confirmation = React.lazy(() => import('./components/registration/confirmation'));
  const ResetPassword = React.lazy(() => import('./components/login/resetPassword'));
  const Questionnaire = React.lazy(() => import('./components/questionnaires'));
  const Login = React.lazy(() => import('./components/login'));
  /* const Homepage = React.lazy(() => import('./components/homepage')); */
  const RouterSuperUser = React.lazy(() => import('./components/superuser/router'));
  const RouterAdmin = React.lazy(() => import('./components/router/admin'));
  const RouterCoach = React.lazy(() => import('./components/router/coach'));
  const RouterStudent = React.lazy(() => import('./components/router/student'));

  const RoleRouter = () => {

    switch (userType) {
      case "superuser":
        return (
            <Suspense fallback={<div>Loading...</div>}>
              <RouterSuperUser />
            </Suspense>
          );
        break;
      case "admin":
        return (
            <Suspense fallback={<div>Loading...</div>}>
              <RouterAdmin />
            </Suspense>
          );
        break;
      case "coach":
        return (
            <Suspense fallback={<div>Loading...</div>}>
              <RouterCoach />
            </Suspense>
          );
      case "student":
        return (
            <Suspense fallback={<div>Loading...</div>}>
              <RouterStudent />
            </Suspense>
          );
        break;
      default:
        return (
            <Suspense fallback={<div>Loading...</div>}>
              {/* <Homepage /> */}
              <Login gohome={true} />
            </Suspense>
          );
    }

  }

  return (
    <div id="wrapper" className={userType}>

      <BrowserRouter>
        <Switch>
          <Route path="/login/:languageID?">
            <Suspense Suspense fallback={<div>Loading...</div>}>
              <Login />
            </Suspense>
          </Route>
          <Route path="/disclaimer/:intervention_id/:language_id">
            <Suspense fallback={<div>Loading...</div>}>
              <Disclaimer />
            </Suspense>
          </Route>
          <Route path="/privacy/:intervention_id/:language_id">
            <Suspense fallback={<div>Loading...</div>}>
              <Privacy />
            </Suspense>
          </Route>
          <Route path="/registration/:languageID?">
            <Suspense fallback={<div>Loading...</div>}>
              <Registration />
            </Suspense>
          </Route>
          <Route path="/Confirmation/:activationCode/:dateTimeCreate/:language_id">
            <Suspense fallback={<div>Loading...</div>}>
              <Confirmation />
            </Suspense>
          </Route>
          <Route path="/Questionnaire">
            <Suspense fallback={<div>Loading...</div>}>
              <Questionnaire />
            </Suspense>
          </Route>
          <Route path="/ResetPassword">
            <Suspense fallback={<div>Loading...</div>}>
              <ResetPassword />
            </Suspense>
          </Route>
          <Route path="*">
            <RoleRouter />
          </Route>
        </Switch>
      </BrowserRouter>

      <div className="overlay"></div>

    </div>
  );
}

export default App;
